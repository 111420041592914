<template>
  <div class="message-container" :class="selectedMessageId === message.id || true ? 'active' : ''">
    <div class="row" :class="selectedMessageId === message.id ? 'active' : ''">
      <div class="atlas-3-sprite  contact-us-icon-close message" ></div>
      <div class="message-title">{{ $props.message.title }} </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: {
    message: {
      type: Object,
      required: true
    },
    selectedMessageId: {
      required: true
    },
  },
  data() {
    return {
  
    };
  }
};
</script>

<style lang="scss" scoped>
.message-container {
  position: relative;
  left: 20px;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  .row {
    position: relative;
    background: #091352;
    border: 3px solid #5c6598;
    border-radius: 20px;
    width: 400px;
    height: 110px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 13px;
    align-items: center;
    &.active {
      border: 3px solid #d1a369;
      background: linear-gradient(
        0deg,
        rgba(96, 51, 180, 1) 0%,
        rgba(67, 34, 120, 1) 100%
      );
  }
    .message{
      margin-left:20px;
    }
    .message-title {
      font-size: 45px;
      line-height: 60px;
      width: 80%;
      height: 100%;
      background: -webkit-linear-gradient(#ffcc66, #ffffcc);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
