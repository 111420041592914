<template>
  <BaseWindow
    width="1770"
    height="1000"
    :show="true"
    :loading="loading"
    @closeWindow="closeWindow"
    innerBackground="linear-gradient(0deg, rgba(42,60,176,1) 0%, rgba(16,28,105,1) 100%)"
  >
    <template>
      <div class="contactInbox-section">
        <div class="head-selectors">
          <div
            class="selector inbox-selector"
            @click="handleInboxTabs('inbox')"
            :class="selectedTab === 'inbox' ? 'active' : ''"
          >
            <span>{{ $t("inboxContact.inbox") }}</span>
          </div>
          <div
            class="selector contact-us-selector"
            @click="handleInboxTabs('contact')"
            :class="selectedTab === 'contact' ? 'active' : ''"
          >
            <span>{{ $t("inboxContact.contactUs") }}</span>
          </div>
        </div>
        <div class="contact-inbox-content">
          <div class="sections-inbox" v-show="selectedTab === 'inbox'">
            <div class="messages">
              <div class="no-message" v-if="getAnnouncements.length === 0">
                {{ $t("chat.anyMessages") }}
              </div>
              <Message
                v-for="(item, index) in getAnnouncements"
                :key="index"
                :message="item"
                :selectedMessageId="selectedMessage.id"
                @click.native="selectMessage(item)"
              />
            </div>
            <div class="message-desc">
              <div class="content" v-if="selectedMessage">
                <div class="heading">
                  {{ selectedMessage.title }}
                </div>
                <div class="description">
                  {{ selectedMessage.text }}
                </div>
                <div class="delete-button" v-show="false">
                  <button class="ui-button ui-button-red">
                    {{ $t("general.delete") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="sections-contact" v-show="selectedTab === 'contact'">
            <div class="form-input">
              <input
                type="text"
                class="mail"
                v-model.trim="contactMail"
                :placeholder="$t('settingsWindow.pinCode.emailAddress')"
              />
              <input
                type="text"
                class="title"
                maxlength="50"
                v-model="contactTitle"
                :placeholder="$t('inboxContact.title')"
              />
              <textarea
                class="desc"
                v-model="contactMessage"
                :placeholder="$t('inboxContact.textHere')"
                maxlength="1000"
              ></textarea>
              <button
                class="ui-button ui-button-green"
                @click="sendContactMessage"
              >
                {{ $t("general.send") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import { mapGetters } from "vuex";
import BaseWindow from "./BaseWindow";
import Message from "./ChatComponents/Message";

export default {
  name: "InboxContactWindow",
  data() {
    return {
      selectedTab: "inbox",
      contactMail: "",
      contactTitle: "",
      contactMessage: "",
      loading: false,
      selectedMessage: {},
    };
  },
  components: {
    BaseWindow,
    Message,
  },
  computed: {
    ...mapGetters("announcements", ["getAnnouncements"]),
  },
  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "inbox");
    },
    handleInboxTabs(tab) {
      this.$data.selectedTab = tab;
    },
    async onShowInboxWindow() {
      this.loading = true;
      await this.$store.dispatch("announcements/getAnnouncements");
      this.loading = false;
    },
    selectMessage(message) {
      this.selectedMessage = message;
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async sendContactMessage() {
      if (this.validateEmail(this.$data.contactMail) == null) {
        return this.$plugins.toast.show({
          text: this.$t("inboxContact.validEmail"),
          duration: 2500,
          variant: "danger",
        });
      }
      if (
        !this.$data.contactMail ||
        !this.$data.contactTitle ||
        !this.$data.contactMessage
      ) {
        return this.$plugins.toast.show({
          text: this.$t("inboxContact.fillTabs"),
          duration: 2500,
          variant: "danger",
        });
      }
      let body = {
        email: this.$data.contactMail,
        title: this.$data.contactTitle,
        text: this.$data.contactMessage,
      };
      let response = await this.$store.dispatch(
        "chat/sendContactUsMessage",
        body
      );
      if (!response) {
        return this.$plugins.toast.show({
          text: this.$t("inboxContact.failedMessage"),
          duration: 2500,
          variant: "danger",
        });
      }
      this.$plugins.toast.show({
        text: this.$t("inboxContact.successfullyMessage"),
        duration: 2500,
        variant: "success",
      });
      this.contactMail = "";
      this.contactTitle = "";
      this.contactMessage = "";
      this.closeWindow();
    },
  },
  mounted() {
    this.onShowInboxWindow();
  },
};
</script>

<style lang="scss" scoped>
.contactInbox-section {
  position: relative;
  width: 100%;
  height: 100%;
  .head-selectors {
    width: 770px;
    height: 82px;
    position: absolute;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(254, 189, 94, 1) 0%,
      rgba(148, 95, 31, 1) 100%
    );
    margin: auto;
    top: 20px;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #ffc272;
    box-shadow: 0 0 15px black, inset 1px 1px 20px black;
    -moz-box-shadow: 0 0 15px black, inset 1px 1px 20px black;
    -webkit-box-shadow: 0 0 15px black, inset 1px 1px 20px black;
    .selector {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #2e0004;
        font-size: 35px;
        font-weight: bold;
        text-transform: uppercase;
      }
      &.active {
        background: rgba(#2e0004, 0.5);
        border-radius: 45px;
        -moz-box-shadow: inset 0 6px 12px #000000;
        -webkit-box-shadow: inset 0 6px 12px #000000;
        box-shadow: inset 0 6px 12px #000000;
        span {
          background: -webkit-linear-gradient(#ffcc66, #ffffcc);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .contact-inbox-content {
    position: relative;
    width: 100%;
    height: 815px;
    top: 150px;
    .sections-contact {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      .form-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button {
          text-transform: uppercase;
        }
        textarea,
        input {
          background: #5e68ab;
          font-size: 45px;
          text-align: center;
          border-radius: 45px;
          border: 3px solid #d1a369;
          color: #d6d6d6;
          margin-bottom: 10px;
          margin-top: 10px;

          &::placeholder {
            color: rgb(255, 255, 255);
            opacity: 1;
          }
          &:focus {
            box-shadow: inset 0 6px 6px #45237a;
            background: #5e68ab;
            color: rgb(255, 255, 255);
          }
        }
        .mail {
          width: 770px;
          height: 82px;
          margin-bottom: 20px;
        }
        .title {
          width: 1250px;
          height: 82px;
          margin-bottom: 20px;
        }
        .desc {
          width: 1350px;
          height: 435px;
          margin-top: 5px;
        }
        button {
          margin-top: 5px;
        }
      }
    }
    .sections-inbox {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .no-message {
        width: 100%;
        text-align: center;
        font-size: 35px;
        position: absolute;
        top: 40%;
      }
      .messages {
        position: relative;
        width: 30%;
        height: 95%;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .message-desc {
        position: relative;
        width: 70%;
        height: 95%;
        background: #091352;
        border: 3px solid #d1a369;
        border-radius: 45px;
        margin-right: 50px;
        .heading {
          font-size: 65px;
          width: 100%;
          height: 10%;
          text-align: center;
          margin-top: 20px;
          background: -webkit-linear-gradient(#ffcc66, #ffffcc);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
          text-transform: uppercase;
        }
        .description {
          font-size: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 100%;
          height: 70%;
        }
        .delete-button {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 30%;
          button {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
</style>
